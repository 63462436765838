@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  font-family: 'Poppins';
  font-weight: 400;
}

.ant-btn-primary {
  background: #7dc490;
  border-color: #7dc490;
  border-radius: 5px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #7dc490;
  border-color: #7dc490;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #fff;
  background: #f4e5dc;
  border-color: #f4e5dc;
}
a {
  color: #7dc490;
}

a:hover {
  color: #7dc490;
}

.ant-btn-link span {
  color: #7dc490;
  text-decoration: underline;
}

.ant-switch-checked {
  background-color: #7dc490;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7dc490;
  border-color: #7dc490;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #7dc490;
}

.ant-checkbox-checked::after {
  border: 1px solid #7dc490;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-drawer-header {
  background-color: #c9a596;
}

.ant-drawer-header .ant-drawer-title {
  color: #fff;
  text-align: center;
}

.habits-modal .ant-modal-header {
  background-color: #5d5d72;
  color: #fff;
}

.ant-drawer-title .anticon-close {
  color: white;
}

.ant-badge-count span {
  color: white;
  text-decoration: none;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #7dc490 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #7dc490;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #7dc490;
}

.ant-radio-checked .ant-radio-inner:focus {
  border-color: #7dc490;
}

.drawer-radio-buttons span.ant-radio + * {
  font-size: 14px;
  font-weight: 800;
  color: #4a4c63;
}

.ant-tabs-nav {
  background-color: #fafafa;
}

.ant-tabs-tab {
  padding-left: 15px;
  padding-right: 15px;

  font-weight: 500;
  color: #4a4c6575;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: #f4e5dc;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4a4c65;
}

.rce-mbox-time {
  bottom: -15px;
}

.ant-upload-draggable-list-item {
  border-top: 2px dashed rgba(0, 0, 0, 0);
  border-bottom: 2px dashed rgba(0, 0, 0, 0);
  width: 120px;
  height: 120px;
}

.ant-upload-draggable-list-item.drop-over-downward {
  border-bottom-color: #1890ff;
}

.ant-upload-draggable-list-item.drop-over-upward {
  border-top-color: #1890ff;
}

.errorModal .ant-modal-content {
  border-radius: 22px;
  overflow: hidden;
}

.errorModal .ant-modal-body {
  padding: 55px 35px 35px;
  background-color: #fdfbfb;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.errorModalTitle {
  font-size: 21px;
  font-weight: 700;
  color: #4a4c65;
}

.errorModalImage {
  margin: 15px 0;
}

.errorModalHelp {
  font-size: 18px;
  font-weight: 500;
  color: #4a4c65;
}

.errorModalHelp a {
  text-decoration: underline;
}

.ant-table {
  background-color: #fafafa;
  color: #4a4c65;
  font-size: 16px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 16px;
}

.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #7dc490;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}

.drawerHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.drawerSubHeader {
  height: 68px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  background-color: #f7f1ea;

  font-size: 16px;
  color: #2e4352;
}

.appointmentsDrawer .ant-drawer-body {
  padding: 0px;
}

.drawerForm {
  padding: 24px;
}

.drawerFormSeparator {
  font-size: 14px;
  color: #8688a7;
  font-weight: 500;
  text-transform: uppercase;

  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 15px 0 5px;
  white-space: nowrap;
}

.drawerSeparatorLine {
  margin-left: 10px;
  background-color: #c7c9dd;
  width: 100%;
  height: 1px;
}

.timesContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.time {
  width: 70px;
  height: 40px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 2.5px;

  font-size: 14px;
  color: #4a4c63;

  border-radius: 5px;
  border: 0.5px solid #d8d8d8;
  cursor: pointer;
}

.time.disabled {
  background-color: #f4f4f4;
  color: #a3a6c4;
  border-color: #f4f3f3;
  text-decoration: line-through;
  cursor: default;
}
.time.selected {
  background-color: #7dc490;
  color: #fff;
}

.drawerActionsContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #c7c9dd;
}

